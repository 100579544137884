<template class="container">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top">
    <div class="col-md-4 d-flex align-items-center">
      <router-link :to="{name:'Page_265_'+language}">{{content.footer.menuItem1}}</router-link>
      <router-link :to="{name:'Page_266_'+language}">{{content.footer.menuItem2}}</router-link>
    </div>
    <div class="col-md-4 justify-content align-items-center">
      <a href="https://www.instagram.com/unitech.competition/" target="_blank" rel="noreferrer"><img src="../assets/img/icons/instagram.svg" alt="Instagram" /></a>
      <a href="https://www.facebook.com/unitechcompetition/" target="_blank" rel="noreferrer"><img src="../assets/img/icons/facebook.svg" alt="Facebook" /></a>
      <p>©CLAAS UniTech {{new Date().getFullYear()}}</p>
    </div>
  </footer>
</template>

<script>

export default{
  name:'Footer',
  props:['initData','contents'],
  data(){
    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      return this.contents[260]
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0;
}

footer {
  position: absolute;
  bottom: 0;
  background-color: var(--dark-gray);
  width: 100%;
  height: 88px;
}

a {
  text-decoration: none;
  color: var(--white);
  margin: 0 10px;
}

div {
  margin: 0 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p{
  color: var(--white);
  mix-blend-mode: normal;
  opacity: 0.4;
  margin: 0 auto;
}
</style>